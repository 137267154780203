import { cva, type VariantProps } from 'class-variance-authority';
import React, { forwardRef, useEffect, useRef, useState } from 'react';

import { omit } from '../util/omit';
import { isNotNullOrEmpty } from '../util/strings';
import { cn } from '../util/styles';

import buttonCssModule from './Button.module.css';
import { Icon, type IconVariant, type IconName } from './Icon';
import { Link } from './Link';

export type ButtonSize = 'large' | 'default' | 'small' | 'smaller';
export type ButtonGrouped = 'horizontal' | 'vertical' | undefined;
export type ButtonType = 'axi-primary' | 'axi-secondary' | 'axi-tertiary' | 'axi-link' | 'axi-toggle';

type ButtonVariant = 'default' | 'red';
type ButtonIconColorVariant = 'default' | 'muted';
type ButtonTextCase = 'upper' | 'capitalize' | 'none' | 'lower';

const buttonVariants = cva(
  [
    buttonCssModule.variables,
    'relative isolate inline-flex flex-shrink-0 flex-grow-0 basis-auto cursor-pointer select-none flex-nowrap items-center justify-center gap-1 rounded align-middle font-medium disabled:cursor-not-allowed',
    'group relative no-underline',
  ],
  {
    variants: {
      variant: {
        gray: '',
        red: '',
      },
      iconVariant: {
        default: '',
        muted: '',
      },
      type: {
        primary: '',
        secondary: '',
        tertiary: '',
        link: [
          'text-[--text-link] underline',
          'enabled:hover:text-[--text-link-hover] enabled:focus:text-[--text-link-hover]',
          'disabled:text-[--text-disabled]',
        ],
        toggle: '', // IGOR HATES THIS BEING A VARIANT. THIS IS A WHOLE DIFFERENT COMPONENT OR AT LEAST A DIFFERENT PROP. but ok, it's fine, just updating styles for now.
      },
      noActiveState: {
        true: '',
        false: '',
      },
      bold: {
        true: 'font-bold',
        false: '',
      },
      textCase: {
        none: '',
        upper: 'uppercase',
        capitalize: 'capitalize',
        lower: 'lowercase',
      },
      size: {
        small: 'min-h-6 p-[3px] text-md leading-none',
        medium: 'min-h-7 p-[5px] text-md leading-none',
        large: 'min-h-8 p-[7px] text-lg leading-none',
      },
      grouped: {
        horizontal: [
          'first:rounded-r-none last:rounded-l-none only:rounded [&:not(:first-child):not(:last-child):not(:only-child)]:rounded-none',
          'only:border-inherit first:border-r-transparent last:border-l-transparent [&:not(:first-child):not(:last-child):not(:only-child)]:border-transparent',
        ],
        vertical: [
          'first:rounded-b-none last:rounded-t-none only:rounded [&:not(:first-child):not(:last-child):not(:only-child)]:rounded-none',
          'only:border-inherit first:border-b-transparent last:border-t-transparent [&:not(:first-child):not(:last-child):not(:only-child)]:border-transparent',
        ],
      },
    },
    compoundVariants: [
      {
        variant: 'gray',
        type: 'primary',
        className: [
          'border border-[--button-primary-border] bg-[--button-primary-bg] text-[--button-primary-text]',
          'enabled:hover:border-[--button-primary-hover-border] enabled:hover:bg-[--button-primary-hover-bg] enabled:hover:text-[--button-primary-hover-text]',
          'enabled:focus:border-[--button-primary-hover-border] enabled:focus:bg-[--button-primary-hover-bg] enabled:focus:text-[--button-primary-hover-text]',
          'disabled:border-[--button-primary-disabled-border] disabled:bg-[--button-primary-disabled-bg] disabled:text-[--button-primary-disabled-text]',
          // gradient stuff
          "before:pointer-events-none before:absolute before:-inset-px before:z-[-1] before:rounded-[inherit] before:bg-gradient-to-t before:to-transparent before:content-['']",
          'before:from-[--button-primary-mask-effect]',
          'enabled:hover:before:from-[--button-primary-hover-mask-effect]',
          'enabled:focus:before:from-[--button-primary-hover-mask-effect]',
          'disabled:before:from-[--button-primary-disabled-mask-effect]',
        ],
      },
      {
        variant: 'gray',
        type: 'secondary',
        className: [
          'border border-[--button-secondary-border] bg-[--button-secondary-bg] text-[--button-secondary-text]',
          'enabled:hover:border-[--button-secondary-hover-border] enabled:hover:bg-[--button-secondary-hover-bg] enabled:hover:text-[--button-secondary-hover-text]',
          'enabled:focus:border-[--button-secondary-hover-border] enabled:focus:bg-[--button-secondary-hover-bg] enabled:focus:text-[--button-secondary-hover-text]',
          'disabled:border-[--button-secondary-disabled-border] disabled:bg-[--button-secondary-disabled-bg] disabled:text-[--button-secondary-disabled-text]',
        ],
      },
      {
        variant: 'gray',
        type: 'tertiary',
        className: [
          'border border-transparent bg-[--button-tertiary-bg] text-[--button-tertiary-text]',
          'enabled:hover:bg-[--button-tertiary-hover-bg] enabled:hover:text-[--button-tertiary-hover-text]',
          'enabled:focus:bg-[--button-tertiary-hover-bg] enabled:focus:text-[--button-tertiary-hover-text]',
          'disabled:bg-[--button-tertiary-disabled-bg] disabled:text-[--button-tertiary-disabled-text]',
        ],
      },
      {
        variant: 'gray',
        type: 'toggle',
        className: [
          'border border-[--button-secondary-border] bg-[--button-secondary-bg] text-[--button-secondary-text]',
          'enabled:hover:border-[--button-secondary-hover-border] enabled:hover:bg-[--button-secondary-hover-bg] enabled:hover:text-[--button-secondary-hover-text]',
          'enabled:focus:border-[--button-secondary-hover-border] enabled:focus:bg-[--button-secondary-hover-bg] enabled:focus:text-[--button-secondary-hover-text]',
          'disabled:border-[--button-secondary-disabled-border] disabled:bg-[--button-secondary-disabled-bg] disabled:text-[--button-secondary-disabled-text]',
        ],
      },
      {
        variant: 'gray',
        type: 'primary',
        iconVariant: 'default',
        className: [
          'icon-[--button-primary-icon]',
          'enabled:hover:icon-[--button-primary-hover-icon]',
          'enabled:focus:icon-[--button-primary-hover-icon]',
          'disabled:icon-[--button-primary-disabled-icon]',
        ],
      },
      {
        variant: 'gray',
        type: 'secondary',
        iconVariant: 'default',
        className: [
          'icon-[--button-secondary-icon]',
          'enabled:hover:icon-[--button-secondary-hover-icon]',
          'enabled:focus:icon-[--button-secondary-hover-icon]',
          'disabled:icon-[--button-secondary-disabled-icon]',
        ],
      },
      {
        variant: 'gray',
        type: 'tertiary',
        iconVariant: 'default',
        className: [
          'icon-[--button-tertiary-icon]',
          'enabled:hover:icon-[--button-tertiary-hover-icon]',
          'enabled:focus:icon-[--button-tertiary-hover-icon]',
          'disabled:icon-[--button-tertiary-disabled-icon]',
        ],
      },
      {
        variant: 'gray',
        type: 'toggle',
        iconVariant: 'default',
        className: [
          'icon-[--button-secondary-icon]',
          'enabled:hover:icon-[--button-secondary-hover-icon]',
          'enabled:focus:icon-[--button-secondary-hover-icon]',
          'disabled:icon-[--button-secondary-disabled-icon]',
        ],
      },
      {
        variant: 'gray',
        type: 'primary',
        iconVariant: 'muted',
        className: [
          'icon-[--button-primary-icon-muted]',
          'enabled:hover:icon-[--button-primary-hover-icon-muted]',
          'enabled:focus:icon-[--button-primary-hover-icon-muted]',
          'disabled:icon-[--button-primary-disabled-icon-muted]',
        ],
      },
      {
        variant: 'gray',
        type: 'secondary',
        iconVariant: 'muted',
        className: [
          'icon-[--button-secondary-icon-muted]',
          'enabled:hover:icon-[--button-secondary-hover-icon-muted]',
          'enabled:focus:icon-[--button-secondary-hover-icon-muted]',
          'disabled:icon-[--button-secondary-disabled-icon-muted]',
        ],
      },
      {
        variant: 'gray',
        type: 'tertiary',
        iconVariant: 'muted',
        className: [
          'icon-[--button-tertiary-icon-muted]',
          'enabled:hover:icon-[--button-tertiary-hover-icon-muted]',
          'enabled:focus:icon-[--button-tertiary-hover-icon-muted]',
          'disabled:icon-[--button-tertiary-disabled-icon-muted]',
        ],
      },
      {
        variant: 'gray',
        type: 'toggle',
        iconVariant: 'muted',
        className: [
          'icon-[--button-secondary-icon-muted]',
          'enabled:hover:icon-[--button-secondary-hover-icon-muted]',
          'enabled:focus:icon-[--button-secondary-hover-icon-muted]',
          'disabled:icon-[--button-secondary-disabled-icon-muted]',
        ],
      },
      {
        variant: 'red',
        type: 'primary',
        className: [
          'border border-[--button-danger-border] bg-[--button-danger-bg] text-[--button-danger-text] icon-[--button-danger-icon]',
          'enabled:hover:border-[--button-danger-hover-border] enabled:hover:bg-[--button-danger-hover-bg] enabled:hover:text-[--button-danger-hover-text] enabled:hover:icon-[--button-danger-hover-icon]',
          'enabled:focus:border-[--button-danger-hover-border] enabled:focus:bg-[--button-danger-hover-bg] enabled:focus:text-[--button-danger-hover-text] enabled:focus:icon-[--button-danger-hover-icon]',
          'disabled:border-[--button-danger-disabled-border] disabled:bg-[--button-danger-disabled-bg] disabled:text-[--button-danger-disabled-text] disabled:icon-[--button-danger-disabled-icon]',
          // gradient stuff
          "before:pointer-events-none before:absolute before:-inset-px before:z-[-1] before:rounded-[inherit] before:bg-gradient-to-t before:to-transparent before:content-['']",
          'before:from-[--button-danger-mask-effect]',
          'enabled:hover:before:from-[--button-danger-hover-mask-effect]',
          'enabled:focus:before:from-[--button-danger-hover-mask-effect]',
          'disabled:before:from-[--button-danger-disabled-mask-effect]',
        ],
      },
      {
        variant: 'gray',
        type: 'primary',
        noActiveState: false,
        className: [
          'enabled:state-on:border-[--button-primary-selected-border] enabled:state-on:bg-[--button-primary-selected-bg] enabled:state-on:text-[--button-primary-selected-text]',
          // gradient stuff
          'enabled:state-on:before:from-[--button-primary-selected-mask-effect]',
        ],
      },
      {
        variant: 'gray',
        type: 'secondary',
        noActiveState: false,
        className: [
          'enabled:state-on:border-[--button-secondary-selected-border] enabled:state-on:bg-[--button-secondary-selected-bg] enabled:state-on:text-[--button-secondary-selected-text]',
        ],
      },
      {
        variant: 'gray',
        type: 'tertiary',
        noActiveState: false,
        className: [
          'enabled:state-on:bg-[--button-tertiary-selected-bg] enabled:state-on:text-[--button-tertiary-selected-text]',
        ],
      },
      {
        variant: 'gray',
        type: 'toggle',
        noActiveState: false,
        className: [
          'enabled:state-on:border-[--button-secondary-selected-border] enabled:state-on:bg-[--button-secondary-selected-bg] enabled:state-on:text-[--button-secondary-selected-text]',
        ],
      },
      {
        variant: 'gray',
        type: 'primary',
        iconVariant: 'default',
        noActiveState: false,
        className: ['enabled:state-on:icon-[--button-primary-selected-icon]'],
      },
      {
        variant: 'gray',
        type: 'secondary',
        iconVariant: 'default',
        noActiveState: false,
        className: ['enabled:state-on:icon-[--button-secondary-selected-icon]'],
      },
      {
        variant: 'gray',
        type: 'tertiary',
        iconVariant: 'default',
        noActiveState: false,
        className: ['enabled:state-on:icon-[--button-tertiary-selected-icon]'],
      },
      {
        variant: 'gray',
        type: 'toggle',
        iconVariant: 'default',
        noActiveState: false,
        className: ['enabled:state-on:icon-[--button-secondary-selected-icon]'],
      },
      {
        variant: 'gray',
        type: 'primary',
        iconVariant: 'muted',
        noActiveState: false,
        className: ['enabled:state-on:icon-[--button-primary-selected-icon-muted]'],
      },
      {
        variant: 'gray',
        type: 'secondary',
        iconVariant: 'muted',
        noActiveState: false,
        className: ['enabled:state-on:icon-[--button-secondary-selected-icon-muted]'],
      },
      {
        variant: 'gray',
        type: 'tertiary',
        iconVariant: 'muted',
        noActiveState: false,
        className: ['enabled:state-on:icon-[--button-tertiary-selected-icon-muted]'],
      },
      {
        variant: 'gray',
        type: 'toggle',
        iconVariant: 'muted',
        noActiveState: false,
        className: ['enabled:state-on:icon-[--button-secondary-selected-icon-muted]'],
      },
      {
        variant: 'red',
        type: 'primary',
        noActiveState: false,
        className: [
          'enabled:state-on:border-[--button-danger-selected-border] enabled:state-on:bg-[--button-danger-selected-bg] enabled:state-on:text-[--button-danger-selected-text] enabled:state-on:icon-[--button-danger-selected-icon]',
          // gradient stuff
          'enabled:state-on:before:from-[--button-danger-selected-mask-effect]',
        ],
      },
    ],
    defaultVariants: {
      variant: 'gray',
      type: 'primary',
      size: 'medium',
      noActiveState: false,
      iconVariant: 'default',
      textCase: 'none',
      bold: false,
    },
  }
);
type ButtonVariants = VariantProps<typeof buttonVariants>;

const compat_variant: Record<ButtonVariant, ButtonVariants['variant']> = {
  default: 'gray',
  red: 'red',
} as const;

const compat_type: Record<ButtonType, ButtonVariants['type']> = {
  'axi-primary': 'primary',
  'axi-secondary': 'secondary',
  'axi-tertiary': 'tertiary',
  'axi-link': 'link',
  'axi-toggle': 'toggle',
} as const;

const compat_size: Record<ButtonSize, ButtonVariants['size']> = {
  smaller: 'small',
  small: 'small',
  default: 'medium',
  large: 'large',
} as const;

export interface BaseButtonProps {
  bold?: boolean;
  className?: string;
  color?: string; // Currently only needed for Login providers which is a bit obnoxious.
  dropdown?: boolean; // Adds caret and disables text-transform.
  expand?: boolean;
  icon?: IconName;
  iconClassName?: string;
  iconSpin?: boolean;
  iconVariant?: IconVariant;
  iconPosition?: 'left' | 'right';
  loading?:
    | boolean
    | {
        delay?: number;
      };
  size?: ButtonSize;
  type?: ButtonType; // Default is `primary`.
  variant?: ButtonVariant;
  iconColorVariant?: ButtonIconColorVariant;
  noActiveState?: boolean;
  textCase?: ButtonTextCase;
  active?: boolean;
  [key: string]: any;
}

interface AnchorButtonProps extends BaseButtonProps, React.HTMLAttributes<HTMLAnchorElement> {
  goBackIfPrevious?: boolean;
  to?: string;
  href?: string;
}

export interface NativeButtonProps extends BaseButtonProps, React.HTMLAttributes<HTMLButtonElement> {}

export type ButtonProps = (AnchorButtonProps | NativeButtonProps) & { grouped?: 'horizontal' | 'vertical' };

export const Button = forwardRef(function Button(
  {
    loading: loadingProp = false,
    htmlType = 'button',
    type,
    size,
    className,
    children,
    icon,
    style: styleProp,
    bold,
    dropdown,
    grouped,
    color,
    disabled,
    expand,
    iconClassName,
    iconSpin,
    iconVariant,
    iconPosition = 'left',
    variant: explicitVariant,
    noActiveState,
    iconColorVariant,
    textCase,
    active,
    onClick,
    ...rest
  }: ButtonProps,
  ref: React.Ref<HTMLButtonElement | HTMLAnchorElement>
) {
  const [loading, setLoading] = useState<boolean | { delay?: number }>(loadingProp);
  const delayTimeout = useRef<number>();

  useEffect(() => {
    if (typeof loadingProp === 'object' && loadingProp.delay) {
      delayTimeout.current = window.setTimeout(() => {
        setLoading(loadingProp);
      }, loadingProp.delay);
    } else {
      setLoading(loadingProp);
    }

    return () => {
      if (delayTimeout.current) {
        clearTimeout(delayTimeout.current);
      }
    };
  }, [loadingProp]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    if (disabled || loading) {
      e.preventDefault();
      e.stopPropagation();

      return;
    }

    (onClick as React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>)?.(e);
  };

  // Axiom code: Add more styles.
  const classes = cn(
    'btn', // Needed for various CSS and Menu auto focus.
    {
      link: type === 'axi-link', // Needed for various CSS
    },
    buttonVariants({
      type: compat_type[type || 'axi-primary'],
      variant: compat_variant[explicitVariant || 'default'],
      iconVariant: iconColorVariant,
      noActiveState: noActiveState,
      size: compat_size[size || 'default'],
      textCase: textCase || 'none',
      bold: bold,
      grouped: grouped,
    }),
    dropdown && 'justify-start',
    className
  );

  const style = {
    ...styleProp,
    ...(isNotNullOrEmpty(color) && !disabled ? { backgroundColor: color, borderColor: color, color: '#ffffff' } : {}),
    ...(expand ? { width: '100%' } : {}),
  };

  let iconNode: JSX.Element | null = null;

  // Font-awesome Icon support
  if (isNotNullOrEmpty(icon)) {
    iconNode = <Icon name={icon} variant={iconVariant} className={iconClassName} />;
  }

  const thereIsNoChildren = React.Children.count(children) === 0;
  const iconOnly = thereIsNoChildren && icon && !dropdown;

  const caretNode = dropdown ? (
    <span className={cn('ml-auto justify-self-end', 'grid h-4 w-4 place-items-center')}>
      <Icon name="chevron-down" />
    </span>
  ) : !iconOnly ? (
    <span />
  ) : null;

  // Override Icon if loading.
  const loadingIcon = loading ? 'spinner-third' : undefined;
  if (loadingIcon) {
    // Add a className to make it spin.
    iconNode = <Icon name={loadingIcon} className="duration-slow-03 ease-linear animate-spin repeat-infinite" />;
  }

  const wrappedIconNode = iconNode ? (
    <span className="flex min-h-4 min-w-4 items-center justify-center">{iconNode}</span>
  ) : null;

  const ButtonContent = (
    <React.Fragment>
      {wrappedIconNode && iconPosition === 'left' ? wrappedIconNode : <span />}
      {children}
      {wrappedIconNode && iconPosition === 'right' ? wrappedIconNode : null}
      {caretNode}
    </React.Fragment>
  );

  if (('href' in rest && rest.href) || ('to' in rest && rest.to)) {
    const linkButtonRestProps = omit(rest as AnchorButtonProps, 'htmlType');

    const { external, goBackIfPrevious, href, to, ...anchorLinkPassthrough } = linkButtonRestProps;

    // prevent navigation when disabled.
    const tabIndex = disabled ? -1 : undefined;

    // return regular anchor or react router Link depending on href or to respectively.
    if (href) {
      const externalProps = external ? { target: '_blank', rel: 'noopener noreferrer' } : {};

      return (
        <a
          href={href}
          tabIndex={tabIndex}
          {...anchorLinkPassthrough}
          {...externalProps}
          className={classes}
          style={style}
          onClick={handleClick}
          ref={ref as React.Ref<HTMLAnchorElement>}
          aria-disabled={disabled}
        >
          {ButtonContent}
        </a>
      );
    }

    if (to) {
      return (
        <Link
          to={to}
          noUnderline
          tabIndex={tabIndex}
          {...anchorLinkPassthrough}
          className={classes}
          goBackIfPrevious={goBackIfPrevious}
          style={style}
          onClick={handleClick}
          ref={ref as React.Ref<HTMLAnchorElement>}
          aria-disabled={disabled}
        >
          {ButtonContent}
        </Link>
      );
    }
  }

  // React does not recognize the `htmlType` prop on a DOM element. Here we pick it out of `rest`.
  const otherProps = omit(rest as NativeButtonProps, 'htmlType', 'to', 'href');

  return (
    <button
      aria-disabled={disabled}
      aria-pressed={type === 'axi-toggle' ? !!active : undefined}
      {...otherProps}
      type={htmlType}
      className={classes}
      onClick={handleClick}
      style={style}
      ref={ref as React.Ref<HTMLButtonElement>}
    >
      {ButtonContent}
    </button>
  );
});
