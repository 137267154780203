// cSpell:ignore msHidden msvisibilitychange screenfull visibilitychange webkitHidden webkitvisibilitychange
import { type Location } from 'history';
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { matchPath } from 'react-router-dom';
import screenfull from 'screenfull';

import { axiom } from '../axiom';
import { axiomDebug } from '../util/axiomDebug';
import { browserHistory } from '../util/browserHistory';
import { safeLocalStorage } from '../util/safeLocalStorage';
import { getTrackingId, trackPage } from '../util/segment';
import { type ExtendedParsedQuery, parse, queryStringParamToString, stringifyWithQM } from '../util/urls';

export type HostingMode = 'cloud' | 'self-host';
export type Theme = 'light' | 'dark' | 'system';

const LOCAL_STORAGE_THEME_KEY = 'axiomTheme';
const LOCAL_STORAGE_SANTA_KEY = 'axiomSanta';
const DEFAULT_THEME = 'system';
export const QUERY_VARIABLE_PREFIX = 'v_';
class ViewStore {
  @observable
  public location: Location = browserHistory.location;

  // 🐉🐉🐉
  // fullScreenMode (formerly fullScreen) no longer accurately reflects the native browser state.
  // It now reflects whether the user entered full screen by clicking a button in Axiom  UI which called `toggleFullScreen()`.
  @observable
  public fullScreenMode: boolean = false;
  @observable
  public isDesktop: boolean = true;
  @observable
  public isCompact: boolean = false;
  @observable
  public isHidden: boolean = false;
  @observable
  public isInitialPageLoad: boolean = true;
  @observable
  public theme: Theme = DEFAULT_THEME;
  @observable
  public themeMode: Exclude<Theme, 'system'>;
  @observable
  public topBannerShowing: boolean = false;

  @observable
  public mode: HostingMode = axiom.mode || 'cloud';
  @observable
  public santaMode: boolean = false;

  private hiddenPropName: string | undefined;
  private visibilityChangeName: string | undefined;

  constructor() {
    makeObservable(this);
    window.addEventListener('resize', this.onWindowResize);
    this.onWindowResize();

    // if theme starts as system, we need to set the mode based on the system preference
    this.setMode(this.theme);

    // Set theme from localStorage if it exists
    const savedTheme = safeLocalStorage.getItem(LOCAL_STORAGE_THEME_KEY);
    if (/^(light|dark|system)$/.test(savedTheme ?? '')) {
      this.setTheme(savedTheme as Theme);
    }

    // Set santa mode from storage
    const savedSanta = safeLocalStorage.getItem(LOCAL_STORAGE_SANTA_KEY);
    if (savedSanta === 'true' || savedSanta === 'false') {
      this.santaMode = Boolean(savedSanta);
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.prefersColorSchemeChanged);

    browserHistory.listen((location) => {
      runInAction(() => {
        this.isInitialPageLoad = false;
        this.location = location;
      });
      // Track in Segment if we've identified a user
      if (getTrackingId()) {
        trackPage();
      }
    });

    // Browser visibility API.
    // Set the name of the hidden property and the change event for visibility
    if (document.hidden !== undefined) {
      // Opera 12.10 and Firefox 18 and later support
      this.hiddenPropName = 'hidden';
      this.visibilityChangeName = 'visibilitychange';
    } else if ((document as any).msHidden !== undefined) {
      this.hiddenPropName = 'msHidden';
      this.visibilityChangeName = 'msvisibilitychange';
    } else if ((document as any).webkitHidden !== undefined) {
      this.hiddenPropName = 'webkitHidden';
      this.visibilityChangeName = 'webkitvisibilitychange';
    }

    if (this.visibilityChangeName && this.hiddenPropName) {
      document.addEventListener(this.visibilityChangeName, this.onVisibilityChange, false);
      this.onVisibilityChange();
    } else {
      console.error('Browser does not have a visibility API.', navigator.userAgent);
    }
  }

  // This might be a bit wasteful but it's useful for Log Stream.
  @computed
  public get activeQuery(): ExtendedParsedQuery {
    return parse(this.location.search);
  }

  // Get external params from query string (params that start with `QUERY_VARIABLE_PREFIX`)
  @computed
  public get activeQueryAplParams(): ExtendedParsedQuery {
    const query = parse(this.location.search);
    const result = {} as Partial<typeof query>;
    for (const key in query) {
      if (key.startsWith(QUERY_VARIABLE_PREFIX)) {
        result[key] = query[key];
      }
    }

    return result;
  }

  // Compute variables from external params
  @computed
  public get activeQueryAplVariables() {
    const params = this.activeQueryAplParams;

    const match = matchPath<{
      orgId: string;
    }>(viewStore.location.pathname, { path: '/:orgId/' });
    const orgId = match?.params.orgId;

    const variables: Record<string, any> = {
      // global variables
      axiom_url: window.location.href,
      axiom_base_url: window.location.origin,
      axiom_org_id: orgId,
    };

    // remove the `QUERY_VARIABLE_PREFIX` prefix from the variable names
    Object.keys(params).forEach((key) => {
      const value = queryStringParamToString(params[key]);

      if (value) {
        variables[key.replace(new RegExp(`^${QUERY_VARIABLE_PREFIX}`, 'i'), '')] = value;
      }
    });

    return variables;
  }

  @computed
  public get activePageRoot(): string {
    const match = matchPath<{
      orgId: string;
      pageRoot: string;
    }>(viewStore.location.pathname, { path: '/:orgId/:pageRoot' });

    return match?.params.pageRoot ?? '';
  }

  // Create a lowercase version of the pathname so we don't have to keep doing it everywhere.
  @computed
  public get activePathname(): string {
    return this.location.pathname;
  }

  @computed
  public get activeUrl(): string {
    return `${this.activePathname}${this.location.search ? '?' : ''}${this.location.search ?? ''}`;
  }

  @computed
  public get hideToolbars() {
    const dashboardParams = matchPath<{
      orgId: string;
      dashboardId: string;
      mode?: string;
    }>(this.location.pathname, { path: '/:orgId/dashboards/:dashboardId/:mode?' })?.params;

    // Only hide toolbars on certain pages:
    // - /:orgId/dashboards/:dashboardId
    // - /:orgId/dashboards/:dashboardId/full
    return !!(
      this.fullScreenMode &&
      dashboardParams?.dashboardId &&
      (!dashboardParams?.mode || dashboardParams?.mode === 'full')
    );
  }

  @computed
  public get isSettingsPage(): boolean {
    const match = matchPath<{
      orgId: string;
      pageId?: string;
    }>(viewStore.location.pathname, { path: '/:orgId/settings/:pageId?' });

    return !!match?.params;
  }

  @computed
  public get isSettingsUsersPage(): boolean {
    const match = matchPath<{
      orgId: string;
    }>(viewStore.location.pathname, { path: '/:orgId/settings/users' });

    return !!match?.params;
  }

  @computed
  public get isSettingsRolesPage(): boolean {
    const match = matchPath<{
      orgId: string;
      roleId?: string;
      modalId?: string;
    }>(viewStore.location.pathname, { path: '/:orgId/settings/roles/:roleId?/:modalId?' });

    return !!match?.params;
  }

  @computed
  public get isSettingsTokenPage(): boolean {
    const match = matchPath<{
      orgId: string;
      tokenId?: string;
      modalId?: string;
    }>(viewStore.location.pathname, { path: '/:orgId/settings/api-tokens/:tokenId?/:modalId?' });

    return !!match?.params;
  }

  @computed
  public get isSettingsGroupsPage(): boolean {
    const match = matchPath<{
      orgId: string;
      groupId?: string;
      modalId?: string;
    }>(viewStore.location.pathname, { path: '/:orgId/settings/groups/:groupId?/:modalId?' });

    return !!match?.params;
  }

  @action.bound
  public onVisibilityChange() {
    if (this.hiddenPropName) {
      this.isHidden = (document as any)[this.hiddenPropName];
    }
  }

  @action.bound
  public onWindowResize() {
    let mediaQuery = window.matchMedia('(min-width: 980px)');
    this.isDesktop = mediaQuery.matches;

    mediaQuery = window.matchMedia('(min-width: 1140px)');
    this.isCompact = mediaQuery.matches === false;

    runInAction(() => {
      // We only want toggleFullScreen can set `fullScreen` to true, so only keep it true if it's true, otherwise change to false.
      this.fullScreenMode = this.fullScreenMode && this.isFullScreen();
    });
  }

  public currentLocationReplaceParams(params: Record<string, string | undefined>) {
    return `${this.location.pathname}${stringifyWithQM({
      ...params,
    })}`;
  }

  public currentLocationWithParams(params: Record<string, string | undefined>) {
    return `${this.location.pathname}${stringifyWithQM({
      ...this.activeQuery,
      ...params,
    })}`;
  }

  // Bind the `this` to `viewStore` because it will be common to hook this up as an onClick handler.
  @action.bound
  public toggleFullScreenMode = () => {
    // Toggles the actual browser fullscreen.
    if (screenfull.isEnabled) {
      if (this.fullScreenMode) {
        void screenfull.exit();
      } else {
        void screenfull.request();
      }
    }

    this.fullScreenMode = !this.fullScreenMode;
  };

  @action.bound
  public setTopBannerShowing(showing: boolean) {
    this.topBannerShowing = showing;
  }

  private isFullScreen() {
    // calculate multiple ways, because `screenfull.isFullscreen` won't catch macOS native full screen.
    return (
      (screenfull.isEnabled && screenfull.isFullscreen) ||
      (screen.availHeight || screen.height - 30) <= window.innerHeight
    );
  }

  @action.bound
  public setTheme(theme: Theme = DEFAULT_THEME) {
    this.theme = theme;
    safeLocalStorage.setItem(LOCAL_STORAGE_THEME_KEY, theme);
    this.setMode(theme);
  }

  @action.bound
  public toggleSanta(mode?: boolean) {
    if (mode === true || mode === false) {
      this.santaMode = mode;
    } else {
      this.santaMode = !this.santaMode;
    }
    safeLocalStorage.setItem(LOCAL_STORAGE_SANTA_KEY, String(this.santaMode));
  }

  private setMode(theme: Theme) {
    // Depending on the theme setting, mode can be either light or dark
    let mode = theme;
    if (mode === 'system') {
      mode = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    }
    window.document.querySelector('html')?.setAttribute('data-theme', mode);

    this.themeMode = mode;
  }

  @action.bound
  private prefersColorSchemeChanged({ matches }: MediaQueryListEvent) {
    if (this.theme === 'system') {
      this.setMode(matches ? 'dark' : 'light');
    }
  }
}

// We really only need one global viewStore so create that instead of doing a Provider.
// This will make it easier to observe `location` from other Stores, etc.
export const viewStore = new ViewStore();

axiomDebug(viewStore);
