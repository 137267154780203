import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { type DefaultValues, type UseFormProps, useForm } from 'react-hook-form';
import { type ZodType } from 'zod';

type OptionalKeys<T> = { [K in keyof T]-?: undefined extends T[K] ? K : never }[keyof T];
type RequiredKeys<T> = { [K in keyof T]-?: undefined extends T[K] ? never : K }[keyof T];
type RemoveOptional<T> = {
  [K in OptionalKeys<T>]: NonNullable<T[K]> | undefined;
} & {
  [K in RequiredKeys<T>]: T[K];
};

/**
 * Compared to the normal `useForm`:
 *
 * - defaultValues are required (this is the important one)
 *   - the purpose of this is to get around a quirk in react-hook-form: an undefined
 *     value in the defaultValues makes the field start out as uncontrolled, which we
 *     don't want.
 *   - it can either be the values, or a function that evaluates to them
 *
 * - you just pass a schema instead of having to pass a resolver
 *
 * - don't need to pass a generic to the form, it gets inferred from the schema
 *   - you will still need to pass a generic to the `onSubmit` in most cases
 *
 * (feel free to use normal `useForm` if this doesn't work for something you're trying to do)
 */
export function useZodForm<TSchema extends ZodType>(
  props: Omit<UseFormProps<TSchema['_input']>, 'resolver' | 'defaultValues'> & {
    schema: TSchema;
    defaultValues:
      | RemoveOptional<DefaultValues<TSchema['_input']>>
      | (() => RemoveOptional<DefaultValues<TSchema['_input']>>);
  }
) {
  const { defaultValues, schema, ...rest } = props;

  // get values if `defaultValues` is a function
  const evaluatedDefaultValues = useMemo(() => {
    if (typeof defaultValues === 'function') {
      return defaultValues();
    } else {
      return defaultValues;
    }
  }, [defaultValues]);

  const form = useForm<TSchema['_input']>({
    ...rest,
    // the casting here is _kind of_ a lie, but we're casting to a _less_ narrow
    // type than the actual input, so it's ok
    defaultValues: evaluatedDefaultValues as DefaultValues<TSchema['_input']>,
    resolver: zodResolver(schema, undefined),
  });

  return form;
}
