import { observable } from 'mobx';

import { checkFeatureFlag } from './featureFlags/useFeatureFlags';

export const QueryFormat = {
  Legacy: 'legacy',
  Tabular: 'tabular',
} as const;

const sessionOverride = observable.box<'legacy' | 'tabular' | undefined>(undefined);

export const setQueryFormatOverride = (format: 'legacy' | 'tabular') => {
  sessionOverride.set(format);
};

export const getQueryFormat = () => {
  const override = sessionOverride.get();
  if (override !== undefined) {
    return override;
  }

  return checkFeatureFlag('tabular-only') ? QueryFormat.Tabular : QueryFormat.Legacy;
};
