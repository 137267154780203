/* eslint-disable react-compiler/react-compiler */
// cSpell:ignore csrf xsrf

import axios from 'axios';
import { type SessionContextValue } from 'next-auth/react';
import { useEffect } from 'react';
import { TrackJS } from 'trackjs';

import { orgStore } from '../stores/OrgStore';
import { getBaseUrl } from '../util/axios';
import { pushError } from '../util/faro';
import { axiomGetAccessToken } from '../util/next-auth';
import { notifyTrace } from '../util/tracing/notifyTrace';

let requestInterceptor: number | undefined;
let responseInterceptor: number | undefined;

interface InitAxiosProps {
  children: React.ReactElement<any, any> | null;
  session: SessionContextValue;
}

export const InitAxios = ({ children, session }: InitAxiosProps) => {
  useEffect(() => {
    // React advises to declare the async function directly inside useEffect
    async function initAxios() {
      const accessToken = axiomGetAccessToken(session);

      // Hack to point axios at the API server.
      axios.defaults.baseURL = getBaseUrl();

      if (requestInterceptor !== undefined) {
        axios.interceptors.request.eject(requestInterceptor);
      }

      if (responseInterceptor !== undefined) {
        axios.interceptors.response.eject(responseInterceptor);
      }

      // Modify all Axios requests.
      // Have to do this in `frontend` instead of `shared` because we don't want to add `viewStore` to `shared`
      // Ideally we could use `window.location` but due to our switch from React Router to NextJs we have to
      // rely on `viewStore` as the sore of truth for the current Url.
      requestInterceptor = axios.interceptors.request.use((config) => {
        config.headers['x-axiom-check'] = 'good';

        // Check if something already set x-axiom-org-id.
        if (config.headers['x-axiom-org-id'] === undefined) {
          // Use Org Id that is in the current pages Url.
          const orgId = orgStore.activeOrgId;

          // Ignore certain routes.
          // (basically all the top level org-less routes and some others)
          // Core has been updated to handle this but leaving this here for now.
          // https://github.com/axiomhq/axiom/pull/6207
          const topLevelRoutes = [
            'monitors',
            'connect',
            'dashboards',
            'datasets',
            'explorer',
            'getting-started',
            'profile',
            'settings',
            'stream',
          ];
          if (orgId) {
            if (!topLevelRoutes.includes(orgId.toLowerCase())) {
              config.headers['x-axiom-org-id'] = orgId;
            }
          }
        }

        // Configure Bearer Token
        config.headers.authorization = `Bearer ${accessToken}`;

        return config;
      });

      // display trace ids of failed requests to axiomers for debugging
      responseInterceptor = axios.interceptors.response.use(
        async (response) => {
          notifyTrace(response);

          return response;
        },
        async (error) => {
          // forgive all the . dot refs but stepping through the debugger one can't see the variable values otherwise
          if (error?.response) {
            const traceId = error?.response.headers?.['x-axiom-trace-id'];
            const fullUrl = `${error?.response.config?.baseURL ?? ''}${error?.response.config?.url}`;
            const pathname = fullUrl.startsWith('http') ? new URL(fullUrl).pathname : fullUrl;
            const fmtError = `${error?.response.status} ${error?.response
              .statusText}: ${error.config.method?.toUpperCase()} ${pathname}`;

            pushError(new Error(fmtError), { traceId: traceId });

            notifyTrace(error.response, true);

            TrackJS.console.log({
              trace_id: traceId,
            });

            TrackJS.track(new Error(fmtError));
          } else {
            pushError(error);
          }

          return Promise.reject(error);
        }
      );
    }

    void initAxios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session.status]); // Re-run whenever the session status changes.

  return children;
};
