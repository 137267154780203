import {
  type Faro,
  getWebInstrumentations,
  initializeFaro,
  LogLevel,
  ReactIntegration,
  ReactRouterVersion,
} from '@grafana/faro-react';
import { type LDClient } from 'launchdarkly-react-client-sdk';
import { Route } from 'react-router-dom';

import { axiom } from '../axiom';

import { browserHistory } from './browserHistory';
import { getEnv } from './env';
import { prepareFaroObject, cloneUnknown, isObject } from './objects';

let faro: Faro | null = null;

export const getFaro = () => faro;

type AplWrangleKind = 'zoom' | 'showEvents';

type EnsureEventType<T extends { name: string; attributes?: Record<string, string | number> }> = T;

/**
 * 🚨🚨🚨
 *
 * Hey you! Yes you! If you're reading this, you're probably about to add
 * a new attribute to ingest with Faro.
 *
 * Before you do so, please ask yourself:
 * - Does this risk leaking PII or any other type of sensitive data that
 *   our users would not want us to have?
 * - Will this data be useful for debugging or investigating issues?
 *
 * If you're not sure, please ask!
 */
type FaroEvent = EnsureEventType<
  | { name: 'annotation_selected' }
  | { name: 'beta_builder_submission' }
  | {
      name: 'chart_input_validation_not_satisfied';
      attributes: {
        apl: string;
        chartType: string;
        errors: string;
      };
    }
  | {
      name: 'legacy_builder_submission';
    }
  | {
      name: 'apl_error_after_wrangle';
      attributes: {
        apl_wrangle_kind: AplWrangleKind;
        trace_id: string;
      };
    }
  | {
      name: 'dashboard_element_created_or_updated';
      attributes: {
        elementType: string;
        interactionType: 'create' | 'update' | 'delete';
        noteElement_elements?: string; // h1, p, etc
      };
    }
  | {
      name: 'trace_opened';
      attributes: {
        spanCount: number; // this is a number, but faro
      };
    }
>;

export const pushEvent = (event: FaroEvent) => {
  const { name } = event;
  const attributes = 'attributes' in event ? event.attributes : undefined;
  faro?.api.pushEvent(name, prepareFaroObject(attributes));
};

export const pushError = (error: Error, opts?: { traceId?: string }) => {
  const traceId = opts?.traceId;
  faro?.api.pushError(error, traceId ? { spanContext: { traceId: traceId, spanId: traceId } } : undefined);
};

export const useFaro = () => {
  return {
    pushEvent: pushEvent,
  };
};

export function initFaro(ldClient: LDClient) {
  if (faro) {
    return;
  }

  if (axiom.enableJSMonitoring) {
    faro = initializeFaro({
      url: '/exapi/instrument',
      app: {
        name: 'app',
        version: process.env.VERSION,
        environment: getEnv(),
      },
      instrumentations: [
        ...getWebInstrumentations({
          captureConsole: true,
          captureConsoleDisabledLevels: [LogLevel.INFO, LogLevel.LOG],
        }),
        new ReactIntegration({
          router: {
            version: ReactRouterVersion.V5,
            dependencies: {
              history: browserHistory as any,
              Route: Route,
            },
          },
        }),
      ],
      batching: {
        enabled: true,
        sendTimeout: 5000, // default is 250
        itemLimit: 50,
      },
    });

    window.document.addEventListener(
      'visibilitychange',
      () => {
        if (window.document.visibilityState !== 'visible') {
          faro?.pause();
        } else {
          faro?.unpause();
        }
      },
      false
    );

    ldClient.on('change', (settings: Record<string, { current: string | boolean | number }>) => {
      const reduced = Object.entries(settings).reduce(
        (acc, [key, value]) => {
          acc[key] = value.current;

          return acc;
        },
        {} as Record<string, string | boolean | number>
      );

      setFeatureFlags(reduced);
    });
  }
}

export const setFeatureFlags = (featureFlags_?: unknown) => {
  if (!isObject(featureFlags_)) {
    return;
  }

  const featureFlags = featureFlags_ as Record<string, string | boolean | undefined>;

  const f = getFaro();

  const attributes = cloneUnknown(f?.metas.value.session?.attributes || {});

  // iterate over featureFlags and set each one in the attributes object
  Object.entries(featureFlags).forEach(([key, value]) => {
    if (value !== undefined) {
      attributes[key] = value.toString();
    } else {
      delete attributes[key];
    }
  });

  f?.api.setSession({
    ...f.metas.value.session,
    attributes: attributes,
  });
};
