import * as SelectPrimitive from '@radix-ui/react-select';
import * as React from 'react';

import { cn } from '../../util/styles';
import { Icon } from '../Icon';

import { type RadixClassNameTypeHelper } from './temporaryRadixTypeHelper';

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

type SelectTriggerProps = React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
  loading?: boolean;
  hasError?: boolean;
  showIcon?: boolean;
};
const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  SelectTriggerProps & RadixClassNameTypeHelper
>(({ loading, hasError, className, children, showIcon = true, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      'flex h-7 w-full items-center justify-between rounded border border-element bg-transparent px-2 py-1 placeholder:text-muted hover:border-hover focus:border-hover active:border-active data-[state=open]:border-active disabled:cursor-not-allowed disabled:opacity-disabled',
      hasError && 'border-[--red-06]',
      className
    )}
    {...props}
  >
    {children}
    <SelectPrimitive.Icon asChild>
      {loading ? (
        <Icon name="spinner" className={cn('h-3.5 w-3.5 opacity-50 ease-ease-in-out-circ', 'animate-spin')} />
      ) : showIcon ? (
        <Icon name="chevron-down" className="h-3.5 w-3.5 opacity-50" />
      ) : null}
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> &
    RadixClassNameTypeHelper & { portalProps?: SelectPrimitive.SelectPortalProps }
>(({ className, children, position = 'popper', portalProps, ...props }, ref) => (
  <SelectPrimitive.Portal {...portalProps}>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        'relative z-dropdown max-h-[var(--radix-select-content-available-height)] min-w-[8rem] overflow-hidden rounded border bg-elevated shadow',
        position === 'popper' && 'translate-y-1',
        className
      )}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport
        className={cn(
          '',
          position === 'popper' &&
            'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]'
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label> & RadixClassNameTypeHelper
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label ref={ref} className={cn('py-1.5 pl-8 pr-2 font-semibold', className)} {...props} />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & RadixClassNameTypeHelper
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      'relative flex w-full cursor-default select-none items-center gap-2 py-2 pl-2 pr-8 transition-colors data-[disabled]:pointer-events-none data-[disabled]:opacity-disabled enabled:hover:bg-highlight enabled:focus-visible:bg-highlight',
      className
    )}
    {...props}
  >
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    <span className="absolute right-2 flex h-3.5 w-3.5 items-center justify-center">
      <SelectPrimitive.ItemIndicator asChild>
        <Icon name="check" className="h-3.5 w-3.5" />
      </SelectPrimitive.ItemIndicator>
    </span>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator> & RadixClassNameTypeHelper
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator ref={ref} className={cn('-mx-1 my-1 h-px bg-separator', className)} {...props} />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export { Select, SelectGroup, SelectValue, SelectTrigger, SelectContent, SelectLabel, SelectItem, SelectSeparator };
